import { Chart, registerables } from 'chart.js';
import 'chartjs-adapter-moment'; // or another adapter to avoid moment
Chart.register(...registerables);
import ChartDataLabels from 'chartjs-plugin-datalabels';
Chart.register(ChartDataLabels);

export const situacaoChartData = {
    type: "doughnut",
    data: {
        labels: [
        ],
        datasets: [{
            label: '',
            data: [],
            backgroundColor: [
            ],
            hoverOffset: 4
        }, ]
    },
    options: {
        responsive: true,
        lineTension: 0,
        // scales: {
        //     yAxes: [{
        //         ticks: {
        //             beginAtZero: true,
        //             padding: 25
        //         }
        //     }]
        // },
        maintainAspectRatio: false,
        aspectRatio: 1.5,
        showDatasetLabels : true,
        plugins: {
            ChartDataLabels,
            datalabels: {
                display: true,
                align: 'center',
                borderRadius: 5,
                backgroundColor: '#fff',
                color: '#555',
                opacity: 0.8,
                //color: '#fff',
                //color: 'transparent',
                font: {
                  size: 11,
                }
            },            
            legend: {
                position: 'right',
                labels: {
                boxWidth: 20,
                boxHeight: 20,
                },              
            },
            title: {
                display: false,
                text: 'Situação dos imóveis'
            }
        }  
    
    },
};

export default situacaoChartData;